.react-source-box {
    position: relative;
    box-sizing: border-box;
    height: 20px;
    padding: 0px;
    margin-top: -2px;
}
.react-source__control {
    min-height: 20px !important;
    border-radius: 0px !important;
}
.react-source__control.react-source__control--is-focused {
    /* border:1px solid red !important */
    box-shadow: none !important;
}
.react-source__value-container {
    padding: 0px 2px !important;
    line-height: 17px;
}
.react-source__indicators{
    display: none !important;
}
.react-source__indicator{
    display: none;
}
.react-source__single-value{
    font-size: 11px !important;
}
.react-source__input-container{
    margin: 0px !important; 
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}


.error .react-source__control{
    background-color: #fce4e4;
    border: 1px solid #cc0033 !important;
    outline: none;
}
.error .react-source1__control{
    background-color: #fce4e4;
    border: 1px solid #cc0033 !important;
    outline: none;
}

.react-source__option{
    font-size: 11px !important;
    padding: 3px 5px !important ;
}

.react-source1__option{
    font-size: 11px !important;
    padding: 3px 5px !important ;
}




/* GROUP CONFIG CSS START */
.react-source-box {
    position: relative;
    box-sizing: border-box;
    height: 20px;
    padding: 0px;
    margin-top: -1px;
}
.react-source1__control {
    min-height: 22px !important;
    border-radius: 0px !important;
}
.react-source1__control.react-source1__control--is-focused {
    /* border:1px solid red !important */
    box-shadow: none !important;
}
.react-source1__value-container {
    padding: 0px 2px !important;
    line-height: 17px;
}
.react-source1__indicators{
    display: none !important;
}
.react-source1__indicator{
    display: none;
}
.react-source1__single-value{
    font-size: 11px !important;
}
.react-source1__input-container{
    margin: 0px !important; 
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}


.react-source__value-container{

}
.react-source1__multi-value{
    margin: 1px 2px !important;
}
.react-source1__multi-value__label {
    border-radius: 0px !important;
    font-size: 88% !important;
    padding: 0px !important;
    padding-left: 2px !important;
}
.react-source1__multi-value__remove{
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.react-source1__control.react-source1__control--is-disabled {
    background-color: #dddddd;
}

/* GROUP CONFIG CSS END */