@media only screen and (min-width: 2401px) and (max-width: 3000px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media only screen and (min-width: 1920px) and (max-width: 2400px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media only screen and (min-width: 1680px) and (max-width: 1919px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media only screen and (min-width: 1501px) and (max-width: 1679px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media only screen and (min-width: 1367px) and (max-width: 1500px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media only screen and (min-width: 1301px) and (max-width: 1366px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;

    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 765px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media (max-width: 1199px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        max-width: 765px;
        /* justify-content: center; */
        /* align-items: center; */
        margin: 0 auto;
        border: 1px solid #e2e2e2;
        min-height: 100vh;
    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .footer {
        max-width: 763px;
    }

    .tradepopupbox {
        max-width: 763px;
    }

    .tradepopupbox {
        max-width: 763px;
    }

}

@media (max-width: 991px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
    }

    .footer-btn-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .tradepopupbox {
        max-width: 763px;
    }
}

@media (max-width: 767px) {
    .desktopscreen {
        display: none;
    }

    .mobilescreen {
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
    }

    .footer-btn-text {
        font-size: 12px;
        margin-top: 0px;
    }
    .himg-trade img {
        width: 3%;
    }
    ul.trade-name-all li {
        color: #0077fe;
        padding: 10px;
        text-align: center;
        font-size: 18px;
        padding: 10px 16px;
        border-bottom: 1px solid #e0e0e0;
    }
}

@media (max-width: 575px) {}

@media (max-width: 500px) {}

@media (max-width: 469px) {}

@media (max-width: 414px) {}

@media (max-width: 390px) {
    .w-20-f img {
        width: 25%;
    }

    .footer-btn-text {
        font-size: 12px;
        margin-top: -3px;
    }
}

@media (max-width: 375px) {}

@media (max-width: 374px) {}

@media (max-width: 320px) {}




/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 3) {}

@media only screen and (min-width: 896px) and (orientation: landscape) {}