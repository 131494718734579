* {
    /* font-family: UbuntuCondensed-Regular,Helvetica,sans-serif; */
    /* font-family: Arial, sans-serif; */
    font-size: 11px;
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'UbuntuCondensed-Regular', sans-serif;
    font-family: 'UbuntuCondensed-Regular';
    /* font-family: 'avenir_next_lt_pro_demi_condensed'; */
}

/* @font-face {
    font-family: UbuntuCondensed-Regular;
    src: url(../fonts/UbuntuCondensed-Regular.ttf);
} */

@font-face {
    font-family: "UbuntuCondensed-Regular";
    src: url(../fonts/UbuntuCondensed-Regular.ttf);
}
@font-face {
    font-family: "avenir_next_lt_pro_demi_condensed";
    src: url(../fonts/avenir_next_lt_pro_demi_condensed.ttf);
}
:root {
    --black: #000;
    --blue: #007bff;
    --menu-color: #b2dcf9;
    --white: #fff;
    --sec-menu-bg: #efeff0;
    --grey-border: #9d9d9d;
    --tab-bg: #f9f9f9;
    --tab-bg-border: #ebebeb;
    --tab-select: #aaa;
    --pag-tab: #696969;
    --leftmenu-tab-bg: #f0f0f0;
    --main-box-bg: #eff8ff;
    --form-disable: #c3c3c3;
    --btn-bg: #5c94d1;
    --update-btn-bg: #45b342;
    --title-bg: #5588af;
    --form-bg: #e1e1e1;
    --clickable-row: #7FFFD4;
    --selectedrow-bg: #00FFFF;
    --btn-disable: #808080;
    --error-color: #cc0033;
    --error-bg: #fce4e4;
    /* --btn-hover-bg:#5c94d1; */
    --btn-danger: #fe6060;
    --btn-danger-hover: #f35262;
    --table-bg: #5588af;
    --btn-green: #009846;

    --text-disable: #b8b8b8;
    --data-sync-light: #aeaeae;
    --black-80: #212529;
    --border-color: #dee2e6;
}
/* .font-bold {
    font-family: "avenir_next_lt_pro_demi_condensed";
}
.font-normal {
    font-family: "UbuntuCondensed-Regular" !important;
} */
.font-bold {
    /* font-weight: bold; */
    font-family: "avenir_next_lt_pro_demi_condensed";
}
.font-normal {
    /* font-weight: bold; */
    font-family: "UbuntuCondensed-Regular" !important;
}
.font-12{
    font-size: 12px !important;
}
.f-normal{
    font-family: "UbuntuCondensed-Regular";
}
.f-arial{
    font-family: 'arial' !important;
}
.f-arial-bold{
    font-family: 'arial' !important;
    font-weight: bold;
}

body.gray {
    background: var(--leftmenu-tab-bg);
    position: relative;
}

body {
    margin: 0;
    padding: 0;
    user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: 'UbuntuCondensed-Regular';
    color: var(--black) !important;
}
.desktopscreen{
    display: flex;
}
.mobilescreen{
    display: none;
}

/* .font-bold {
    font-weight: bold;
} */



.text-right {
    text-align: right;
}



.text-center {
    text-align: center;
}

/* FOOTER CSS START */
.footer {
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    padding: 8px 0px;
    z-index: 999;
    background: white;
}
.w-20-f {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footer-btn-text {
    font-size: 22px;
    margin-top: 4px;
}
.w-20-f img{
    width: 40%;
}
.footer ul {
    width: 100%;
    display: inline-block;
    margin: 0;
    position: relative;
    padding: 0px;
}
.footer ul li {
    display: inline-block;
    margin: 0;
    position: relative;
    text-align: center;
}

/* HEADER CSS START */
.header{
    /* height: 60px; */
    background-color: #fff;
    z-index: 999;
    position: sticky;
    top: 0px;
    padding: .35rem !important;
}

.search .has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.675rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.search .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #eeeeee;
}
.search .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #eeeeee;
    outline: 0;
    box-shadow: none;
}
.search .form-group {
    margin-bottom: 5px;
}
.himg img{
    width: 17px;
}
.header-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-right: 5px;
}
.header-name {
    font-size: 13px;
    text-transform: capitalize;
    color: #000;
    font-family: "avenir_next_lt_pro_demi_condensed";
}


/* MIDDLE CSS START */
.middle {
    background-color: #fff;
    width: 100%;
    position: relative;
}
.table td, .table th {
    padding: .35rem !important;
    vertical-align: top;
    border-top: transparent !important;
}
.font-11{
    font-size: 11px;
}
.symbol-name{
    font-size: 13px;
    text-transform: capitalize;
    color: #000;
    font-family: "avenir_next_lt_pro_demi_condensed";
    line-height: 18px;
}
.text-blue{
    color: #0077fe !important;
}
.text-grey{
    color: #666666 !important;
}
.text-red{
    color: #e82c2b !important;
}
.text-black{
    color: #000 !important;
}
.symbol-high-low{
    font-size: 11px;
    color: #666666;
}
.symbol-price{
    font-size: 13px;
    color: #000;
    font-family: "avenir_next_lt_pro_demi_condensed";
    line-height: 18px;
}
.text-bigger{
    font-size: 24px;
    font-family: "avenir_next_lt_pro_demi_condensed";
}

.table-arrow-red, .table-arrow-blue{
    position: relative;
    padding-top: 7px;
}
.table td.w-50{
    padding-left: 6px !important;
}
.table-arrow-red:after {
    content: '';
    position: absolute;
    left: -6px;
    right: 0;
    /* margin: 0 auto; */
    width: 12px;
    height: 0;
    border-top: 12px solid #e82c2b;
    border-left: 0px solid transparent;
    border-right: 15px solid transparent;
    top: 1px;
}

.table-arrow-blue:after {
    content: '';
    position: absolute;
    left: -6px;
    right: 0;
    /* margin: 0 auto; */
    width: 12px;
    height: 0;
    border-top: 12px solid #0077fe;
    border-left: 0px solid transparent;
    border-right: 15px solid transparent;
    top: 1px;
}

.icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover;
}
.quotes-icon{
    background-image:url(../../public/images/Quotes.svg);
}
.w-20-f:hover .quotes-icon, .w-20-f:focus .quotes-icon, .w-20-f.active .quotes-icon{
    background-image:url(../../public/images/Quotes-hover.svg);
}
.chart-icon{
    background-image:url(../../public/images/Chart.svg);
}
.w-20-f:hover .chart-icon, .w-20-f:focus .chart-icon, .w-20-f.active .chart-icon{
    background-image:url(../../public/images/Chart-hover.svg);
}
.trade-icon{
    background-image:url(../../public/images/Trade.svg);
}
.w-20-f:hover .trade-icon, .w-20-f:focus .trade-icon, .w-20-f.active .trade-icon{
    background-image:url(../../public/images/Trade-hover.svg);
}
.history-icon{
    background-image:url(../../public/images/History.svg);
}
.w-20-f:hover .history-icon, .w-20-f:focus .history-icon, .w-20-f.active .history-icon{
    background-image:url(../../public/images/History-hover.svg);
}
.setting-icon{
    background-image:url(../../public/images/Setting.svg);
}
.w-20-f:hover .setting-icon, .w-20-f:focus .setting-icon, .w-20-f.active .setting-icon.active{
    background-image:url(../../public/images/Setting-hover.svg);
}
.active .footer-btn-text.text-grey{
    color:#0077fe !important ;
}
.w-20-f:hover .footer-btn-text.text-grey, .w-20-f:focus .footer-btn-text.text-grey{
    color:#0077fe !important ;
}


.w-40-tb{
    width: 40%;
}
.w-20-tb{
    width: 20%;
}

.table td.w-40-tb {
    padding-left: 6px !important;
}
.table-arrow-red-2, .table-arrow-blue-2{
    position: relative;
}
.table-arrow-red-2:after {
    content: '';
    position: absolute;
    left: -3px;
    right: 0;
    /* margin: 0 auto; */
    width: 5px;
    height: 0;
    border-top: 35px solid #e82c2b;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    top: 2px;
}

.table-arrow-blue-2:after {
    content: '';
    position: absolute;
    left: -3px;
    right: 0;
    /* margin: 0 auto; */
    width: 5px;
    height: 0;
    border-top: 35px solid #0077fe;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    top: 2px;
}
.mb-30{
    margin-bottom: 30px !important;
}
.mb-70{
    margin-bottom: 70px !important;
}
.thead-fixed .table thead th {
    background-color: #fff;
    color: #000!important;
    position: sticky;
    top: 74px;
    z-index: 9;
}
.table.edittable td, .table.edittable th {
    padding: .55rem !important;
    vertical-align: top;
    border-top: transparent !important;
}
.bgmain{
    background-color: #f2f1f6 !important;
}
.h100{    
    height: 100vh;
}
.settingbox{
    background-color: #fff;
    border-top:1px solid #e6e6e7;
    border-bottom:1px solid #e6e6e7;
    padding: 10px 5px;
}
.settinglogo img{
    width: 15%;
    display: flex;
    margin: 0 auto;
}
.font-20{
    font-size: 20px !important;
}
.font-18{
    font-size: 18px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-14{
    font-size: 14px !important;
}
.font-12{
    font-size: 13px !important;
}
.font-11{
    font-size: 11px !important;
}
hr{
    border: 1px solid #e6e6e7;
    margin: 5px 0px;
}
.v-middle{
    vertical-align: middle !important;
}
.rightarrow{
    width: 15px;
}
.log-logo{
    width: 26px;
}

.w-40 {
    width: 40px;
}
.br1{
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.width-info{
    width: 50px;
}
.tradetb.table td, .tradetb.table th {
    padding: .15rem !important;
    vertical-align: top;
    border-top: transparent !important;
    line-height: 15px;
}

.himg-trade img{
    width: 5%;
}

.loginscreen .w-25{
    width: 35% !important;
}

.loginscreen .w-75{
    width: 65% !important;
}
.loginscreen .w-75 input, .loginscreen .w-75 input:focus-visible {
    border: transparent !important;
    text-align: right;
    padding-right: 6px;
    outline: none;
    font-family: arial;
}
.loginscreen.table td, .loginscreen.table th {
    padding: .55rem !important;
    vertical-align: top;
    border-top: transparent !important;
}
button.btnmain {
    color: #fff;
    font-weight: 500;
    line-height: 18px;
    min-width: 70px;
    padding-bottom: 6px;
    padding-top: 3px;
    width: auto;
    background: #007bff;
    border: 1px solid #007bff;
    border-radius: 0px;
    text-align: center;
    width: 100%;
    height: 30px;
    font-size: 14px;
    border-radius: 4px;
}
.mtext{
    height: 25px;
    line-height: 25px;
}


.tradetb .w-25{
    width: 35% !important;
}

.tradetb .w-75{
    width: 65% !important;
}
.rotate-90{
    rotate: -90deg;
}
.rotate12{
    width: 5%;
}

.tradebtngroup .btn-group {
    background: #eee;
    height: 30px;
    padding: 2px;
    border-radius: 3px;
}
.tradebtngroup .btn-primary {
    color: #000;
    background-color: transparent; 
    border-color: transparent;
    padding: 4px 30px;
    border-radius: 5px;
}
.tradebtngroup .btn-primary:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}
.tradebtngroup .btn-primary.focus, .tradebtngroup .btn-primary:focus {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
}
.tradebtngroup .btn-primary:not(:disabled):not(.disabled).active, .tradebtngroup .btn-primary:not(:disabled):not(.disabled):active, .show>.tradebtngroup .btn-primary.dropdown-toggle {
    color: #000 ;
    background-color: #fff;
    border-color: #fff;
}
.tradebtngroup .btn.focus, .tradebtngroup .btn:focus {
    outline: 0;
    box-shadow: none !important;
}
.tradebtngroup .btn-group .btn {
    border: 5px !important;
}
.tradebtngroup .btn-group>.btn-group:not(:last-child)>.btn, .tradebtngroup .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.tradebtngroup .btn-group>.btn-group:not(:first-child)>.btn, .tradebtngroup .btn-group>.btn:not(:first-child) {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.buysellbg {
    background-color: #e9e9e9;
    width: 100%;
    height: auto;
    padding: 8px 10px;
}

.sellbtn {
    background-color: #dd5e4f;
    text-align: center !important;
    color: #fff;
    height: 35px;
    width: 100%;
    border: none;
    font-size: 14px;
}
.buybtn {
    background-color: #4a93f2;
    text-align: center !important;
    color: #fff;
    height: 35px;
    width: 100%;
    border: none;
    font-size: 14px;
}

.tradescreen{
    margin: 5px 0px 10px 0px;
}

.tradescreen ul {
    width: 100%;
    display: inline-block;
    margin: 0;
    position: relative;
    padding: 0px;
}
.tradescreen ul li {
    display: inline-block;
    margin: 0;
    position: relative;
    text-align: center;
    font-size: 14px;
    font-family: arial;
}
.inputbr0, .loginscreen .inputbr0:focus-visible {
    border: transparent !important;
    text-align: right;
    padding-right: 6px;
    outline: none;
}
.br-bottom{
    border-bottom: 1px solid #dee2e6;
}
.tradesymlist td {
    border-bottom: 1px solid #dee2e6;
    padding: 6px 5px!important;
}


.tradebtngroup .btn-primary.active {
    color: #000;
    background-color: #fff;
    border-color: #fff;
}
.width30{
    width: 30px;
}
.limit-plus-minus{
    width: 60%;
}



.tradepopupbox {
    display: block;
    background-color: rgb(0 0 0 / 30%);
    height: 100vh;
    width: 100%;
    z-index: 999999;
    position: fixed;
    overflow: hidden;
}
.tradebox {
    position: relative;
    height: 100vh;
}
.tradebox-inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: block; */
    flex-direction: column;
}
.trade-cancel {
    background-color: #fff;
    border-radius: 6px;
    width: 96%;
    color: #0077fe;
    /* padding: 10px; */
    padding: 1px 10px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 4px;
    font-family: "avenir_next_lt_pro_demi_condensed";
}
.trade-main-name {
    background-color: #fff;
    border-radius: 6px;
    width: 96%;
    color: #0077fe;
    padding: 0px;
    text-align: center;
    font-size: 18px;
    margin-bottom: 4px;
    font-family: "avenir_next_lt_pro_demi_condensed";
}
ul.trade-name-all{
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;

}
ul.trade-name-all li {
    color: #0077fe;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
}
.bg-tr{
    background-color: #f0f0f0 !important;
}


/* DETAILS POPUP CSS */
.detailspopup{
    background-color: #fff;
    width: 100%;
    height: 100vh;
}
.detailspopup .table thead th {
    background-color: #5588af;
    background-color: var(--title-bg);
    color: #fff!important;
    color: var(--white)!important;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 9;
}
.table-strip tr:nth-child(2n) {
    background-color: rgba(85,136,175,.08)!important;
}
.table-strip tr:nth-child(odd) {
    background-color: #fff!important;
}


ul.tradeui{
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
ul.tradeui li {
    color: #0077fe;
    padding: 7px !important;
    text-align: center;
    font-size: 14px !important;
    border-bottom: 1px solid none !important;
    font-family: "UbuntuCondensed-Regular" !important;
}


.tradeboxbg-trade{
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        color: #000;
}
.tradeboxbg-trade1{
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    color: #000;
}
.bg-white{
    background-color: #fff !important;
}


.trade-name-main{
        display: flex;
        justify-content: space-between;
        padding: 5px;
        color: #000;
        font-family: "avenir_next_lt_pro_demi_condensed";
}
.symbol-grey-text {
    font-size: 12px;
    color: #9d9d9d;
    text-align: left;
    padding: 0px 6px 5px;
}

[disabled].sellbtn, [disabled].buybtn, [disabled].closebtn {
    background-color: #aaa;
}


.closebtn {
    background-color: #ffc107;
    text-align: center !important;
    color: #000;
    height: 35px;
    width: 100%;
    border: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yellow-close-btn-id-span {
    font-size: inherit;
    width: 110px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 5px;
}

.limit-dr {
    border: 1px solid #ced4da;
    background: #fff;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    width: 60%;
    line-height: 1.5;
    margin: 0 auto;
    height: 23px;
}
/* .form-select {
    -moz-padding-start: calc(.75rem - 3px);
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-color: var(--white);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-position: right .32em center;
    background-repeat: no-repeat;
    background-size: 9px 14px;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}
.form-select.inputborder {
    background-color: #fff!important;
    background-color: var(--white)!important;
} */
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}
.btn.disabled, .btn:disabled {
    opacity: .65;
}


button.btnmain {
    width: auto;
    background: var(--btn-bg);
    color: var(--white);
    border: 1px solid var(--btn-bg);
    width: auto;
    border-radius: 0px;
    line-height: 18px;
    padding-top: 3px;
    padding-bottom: 2px;
    min-width: 70px;
    font-weight: 500;
}

button.btnmain:hover,
button.btnmain:focus {
    border: 1px solid var(--btn-bg);
    background: #4685c8;
    color: #fff;
    box-shadow: 0 0 0 0.05rem rgb(58 128 224 / 50%);
    line-height: 18px;
}
button.btnmain-danger {
    background-color: #fe6060;
    background-color: var(--btn-danger);
    border: 1px solid #fb8a8a;
    border-radius: 0;
    color: #fff;
    color: var(--white);
    font-weight: 500;
    line-height: 24px;
    min-width: 70px;
    padding-bottom: 2px;
    padding-top: 3px;
    width: auto;
}
.btn {
    background-color: initial;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #212529;
    cursor: pointer;
    display: inline-block;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
}
.datafeed .modal-body, .login .modal-body, .newsecurity .modal-body, .newsymbol .modal-body, .newuser .modal-body {
    background-color: #f0f0f0!important;
    background-color: var(--leftmenu-tab-bg)!important;
}
.modal-header .btn-close {
    margin: calc(var(--bs-modal-header-padding-y)*-.5) calc(var(--bs-modal-header-padding-x)*-.5) calc(var(--bs-modal-header-padding-y)*-.5) auto;
    padding: calc(var(--bs-modal-header-padding-y)*.5) calc(var(--bs-modal-header-padding-x)*.5);
}
.btn-close {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    height: 1em;
    opacity: 1;
    padding: 10px .25em!important;
    width: 1em;
}
.datafeed .modal-header, .login .modal-header, .newsecurity .modal-header, .newsymbol .modal-header, .newuser .modal-header {
    padding: 3px 10px;
}
.hr {
    background: #c1c1c1;
    height: 1px;
    margin-bottom: 6px;
    margin-top: 6px;
    width: 100%;
}


.btn-primary.disabled,
.btn-primary:disabled {
    color: var(--black) !important;
    background-color: #a6a6a6 !important;
    border-color: #a6a6a6 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #a6a6a6!important;
    border-color: #a6a6a6!important;
    color: #000!important;
    color: var(--black)!important;
}

.datafeed .modal-title, .login .modal-title, .newsecurity .modal-title, .newsymbol .modal-title, .newuser .modal-title {
    font-size: 14px;
}


.quoteul {
    list-style-type: none;
    padding: 0px 8px;
    margin-bottom: 0px;
}
.quoteul {
    padding: 3px 6px;
}

.himg img.settingheight {
    width: 15px;
}
hr {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}
.table thead th {
    background-color: #5588af;
    color: #fff!important;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 9;
}
.inviocetable tr td {
    font-size: 10px !important;
    padding: 2px !important;
}   
.font-10{
    font-size: 10px !important;
}
.mb-50{
    margin-bottom: 50px !important;
}
.f10{
    font-size: 10px !important;
}


button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.quoteul li{
    margin-bottom: 6px !important;
}
.h10 {
    height: 2px !important;
    line-height: 1px !important;
    padding: 0px !important;
}
.ltradescreen tr td{
    font-family: arial;
    font-size: 12px !important;
}


.dd-flex {
    display: flex !important;
    justify-content: right;
    align-items: center;
}



.max-w {
    display: inline-block;
    line-height: 16px;
    margin-left: 3px;
    max-width: 100px!important;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.version-fixed {
    margin-bottom: 58px;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
}