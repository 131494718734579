

#page {
	/* height: 100vh; */
	background-color: #f0f0f0;
	display: grid;
	grid-template-areas:
		'header header header header header'
		'leftcol leftdragbar tabs tabs tabs';
		/* 'bottomdragbar bottomdragbar bottomdragbar bottomdragbar bottomdragbar'
		'footer footer footer footer footer'; */
	grid-template-rows:  39px 94.3vh;
	grid-template-columns: 2fr 4px 10fr;
	overflow: hidden;	
}

/*****************************/
#header {
    background-color: #f0f0f0;
    overflow: auto;
    grid-area: header;
    height:45px;
    width: 100%;
    /* position: relative; */
}

#leftcol {
	background-color: #fff;
	overflow: auto;
	grid-area: leftcol;
	border: 1px solid #696969;
	/* border-left: 1px solid #696969;
    border-top: 1px solid #696969;
    border-right: 1px solid #696969;
    border-bottom: 1px solid transparent; */
	padding: 10px 0px 10px 10px;
	/* height: 60vh; */
	/* max-height: 60vh; */
	position: relative;
}

#leftdragbar {
	background-color: #f0f0f0;
	grid-area: leftdragbar;
	cursor: ew-resize;
}

#bottomdragbar {
	background-color: #f0f0f0;
	grid-area: bottomdragbar;
	cursor: ns-resize;
	/* height: 5px; */
}

#tabs {
	background-color: #fff;
	overflow: auto;
	grid-area: tabs;
	border: 1px solid #696969;
	/* height: 60vh; */
	/* max-height: 60vh; */
	position: relative;
}

#tabpages {
	background-color: #888888;
	overflow: auto;
	grid-area: tabpages;
}

#rightdragbar {
	background-color: black;
	grid-area: rightdragbar;
	cursor: ew-resize;
}


#rightcol {
	background-color: #aaaaaa;
	overflow: auto;
	grid-area: rightcol;
}

#footer {
	/* background-color: lightblue; */
	overflow: auto;
	grid-area: footer;
	background: #fff;
    border-left: 1px solid #696969;
    border-top: 1px solid #696969;
    border-right: 1px solid #696969;
	border-bottom: 1px solid transparent;
	/* border: 1px solid #696969; */
	/* max-height: 32vh; */
	/* height: 32vh; */
}